<template>
  <div class="feature ever-feature reverse">
    <div class="feature-content">
      <img :src="`${useCdnUrl()}/assets-2024/ever/ever-logo.png`" alt="Ever" />
      <h2>Intimate Vows,<br />Iconic Views</h2>
      <p>
        Welcome to EVER, where the magic of New York City becomes a main character in your love
        story. Wedding ceremonies, vow renewals, proposals, and promise pledges at EVER offer an
        unparalleled experience as you declare your love in one of the world's most iconic settings.
      </p>

      <TheButton to="/ever" tag="NuxtLink" class="ever-callout">Reserve your date</TheButton>

      <p class="coming-soon">Be the first to celebrate your love at the iconic One Times Square</p>
    </div>

    <div class="feature-image-effect">
      <SplitImage :src="`${useCdnUrl()}/assets-2024/ever/ever-poster.png`" />
    </div>

    <div
      class="feature-image-mobile"
      :style="{ 'background-image': `url(${useCdnUrl()}/assets-2024/ever/ever-poster.png)` }"
    />
  </div>
</template>

<style lang="scss" scoped>
.ever-feature {
  .feature-content {
    background-color: var(--primary-color);

    .ever-callout {
      background: #4d757a;
      border-width: 0;

      &:hover {
        background-color: white;
        color: #4d757a;
      }
    }
  }
}
</style>
